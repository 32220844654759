<template>
  <div class="w-100 m-0 p-0">
    <div class="main_table" v-if="groupDetails" >
        <TableHeaderSection
          @toggleModal="ToggleTaskModal('add')"
          :title="`${localization('Group members')}`"
          :text="`${localization('Organize your contacts into groups and see the group dynamic.')}`"
          :buttonText="`${localization('Add new member')}`"
          :total="groupDetails.groups_members_count + ' members'"
        ></TableHeaderSection>
        <div class="header_container just_custom_table_tasks border">
          <CustomTable
            :count="groupDetails.groups_members_count"
            :DataItems="groupDetails.members"
            @Refresh="refresh"
            @reset="filter = {}"
            :filter="filter"
            :title="'Tasks'"
            hidden_export_import
            admin_permission="admin_tasks"
            edit_permission="add_tasks"
            class="team_members"
            @selection="selections"
            :isGroup="detailsPage=='detailsPage' ? false : true"
          >
            <template #columns>
              <el-table-column type="selection" width="40"></el-table-column>
              <el-table-column prop="first_name" :label="`${localization('Member Name')}`">
                <template slot-scope="props">
                  <div class="d-flex ">
                    <h5 class="m-0">{{ props.row.first_name }} </h5>
                    <h5 class="m-0">{{ props.row.last_name }}</h5>
                  </div>
                  <div>
                    <span>{{ props.row.learner }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="sequence" :label="`${localization('sequence')} (S)`">
                <template slot-scope="props">
                  <span class="text-white bg-info rounded-pill px-3 py-2">{{ props.row.sequence }} - {{ props.row.classification.sequence }}</span>
                  <!-- <span class="text-white bg-info rounded-pill px-3 py-2">{{ props.row.sequence }} </span> -->
                </template>
              </el-table-column>
              <el-table-column prop="precision" :label="`${localization('Precision')} (P)`">
                <template slot-scope="props">
                  <span class="text-white bg-success rounded-pill px-4 py-2">{{ props.row.precision }} - {{ props.row.classification.precision }}</span>
                  <!-- <span class="text-white bg-success rounded-pill px-4 py-2">{{ props.row.precision }} </span> -->
                </template>
              </el-table-column>
              <el-table-column prop="technical_reasoning " :label="`${localization('Technical Reasoning ')} (T)`">
                <template slot-scope="props">
                  <span class="text-white bg-warning rounded-pill px-4 py-2">{{ props.row.technical_reasoning }} - {{ props.row.classification.technical_reasoning }}</span>
                  <!-- <span class="text-white bg-warning rounded-pill px-4 py-2">{{ props.row.technical_reasoning }} </span> -->
                </template>
              </el-table-column>
              <el-table-column prop="confluence" :label="`${localization('Confluence')} (C)`">
                <template slot-scope="props">
                  <span class="text-white bg-danger rounded-pill px-4 py-2">{{ props.row.confluence }} - {{ props.row.classification.confluence }}</span>
                  <!-- <span class="text-white bg-danger rounded-pill px-4 py-2">{{ props.row.confluence }} </span> -->
                </template>
              </el-table-column>
              <el-table-column prop width="80">
                <template slot-scope="scope">
                  <div class="td_actions flex justify-content-end">

                    <router-link
                      v-if="hasPermission('show_tasks') && detailsPage=='detailsPage'"
                      :to="`/user-details/${scope.row.id}`"
                      v-tooltip.top-center="
                          `${localization('Show')}`
                        "
                      class="btn btn-info btn-link btn-xs px-1 m-0"
                    >
                      <img src="@/assets/new-theme/eye.png" alt="eye" />
                    </router-link>
                    <span class="btn btn-warning btn-simple btn-link px-1 m-0"
                      v-tooltip.top-center="
                        `${localization('Restore')}`
                      "
                      @click="retriveTeamMember(scope.row.id)"
                      v-if="scope.row.user_status && scope.row.user_status != 1"
                    >
                      <img src="@/assets/images/icons/reverse-left.png" alt="delete" />
                    </span>
                    <span class="btn btn-warning btn-simple btn-link px-1 m-0"
                      v-tooltip.top-center="
                        `${localization('Delete')}`
                      "
                      @click="delete_member_from_team(scope.row.id)"
                      v-else
                    >
                      <img src="@/assets/new-theme/trash.png" alt="delete" />
                    </span>
                    
                  </div>
                </template>
              </el-table-column>
            </template>
          </CustomTable>
        </div>
        <div class="mt-1 d-flex justify-content-center mb-5">
            <button
              @click="$router.push({name: 'TeamsView'})"
              class="lci_btn save mx-4"
              v-if="detailsPage != 'detailsPage'"
            >
              {{ localization("Back to teams") }}
            </button>
        </div>
    </div>
    <el-dialog
      title="Invite members"
      :visible.sync="show_invite_modal"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      :close-on-click-modal="true"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/icons/users-plus.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title font-weight-bold mt-3">
          {{ localization("Invite members to your group *") }}
        </h3>
        <p class="description">
          {{ localization("Invite members to your group by any of the following methods.") }}
        </p>
      </div>
      <el-form
        ref="contactRef"
      >
          <el-form-item :label="`${localization('Members')}`">
            <el-select
              ref="users"
              :placeholder="`${localization('Select Members')}`"
              style="width: 100%"
              v-model="selected_additionam_group_members"
              filterable
              multiple
              remote
              reserve-keyword
              :remote-method="search"
              :loading="loadingValue"
            >
              <el-option
                v-for="user in contactList"
                :key="user.id"
                :label="user.first_name + ' ' + user.last_name"
                :value="user.id"
              >
                <span style="color: #8492a6; font-size: 13px">
                  <i class="el-icon-user-solid"></i>
                  {{ user.first_name + " " + user.last_name }}
                </span>
              </el-option>
            </el-select>
            <div>
              {{ localization("Or invite members by email") }}
            </div>
            <el-form-item
              label="Email"
              style="width: 100%"
              prop="questions_options"
            >
              <el-select
                v-model="emails"
                no-data-text="Type Email"
                multiple
                filterable
                allow-create
                default-first-option
                class=""
                style="width: 100%"
                placeholder="Type Email"
              >
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item label="Institution Users" prop="">
            <el-select
              v-model="selected_institutions_users"
              :placeholder="`${localization('Please select user')}`"
              style="width: 100%"
              multiple
              filterable
            >
              <el-option
                v-for="item in institutionUsersList"
                :key="item.id"
                :label="item.first_name + ' ' + item.last_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
      </el-form>
      <div class="lci_modal_buttons mt-5">
        <button @click="resetForm" class="lci_btn_modal_cancel ">
          {{ localization("Cancel") }}
        </button>
        <button @click="addAdditionalMembersToGroup" class="lci_btn save lci_btn_modal_cancel">
          {{ localization("Invite") }}
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
export default {
  components: {
    Card,
    ChartCard,
    StatsCard
  },
  computed: {
    ...mapGetters({
      loadingValue: ["groups/usersLoad"],
      contactList: ["contacts/contactList"],
      institutionUsersList: ["groups/getInstUsers"]
    }),
    groupId() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },
  props:{
    detailsPage: {
      type: String,
      default: '',
    },
    groupDetails:{
      type: Object,
      default: null,
    }
  },
  data(){
    return {
      selected: [],
      filter: {},
      show_invite_modal: false,
      emails: [],
      selected_additionam_group_members: [],
      selected_institutions_users: [],
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
      },
    }
  },
  methods: {
    refresh(query){
      this.$emit('refresh', query)
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
    },
    ToggleTaskModal() {
      this.show_invite_modal = true;
    },
    resetForm(){
      this.show_invite_modal = false;
    },
    search(query) {
      this.usersRoles(query);
    },
    usersRoles(search = "", limit = 10) {
      this.$store.dispatch("contacts/GetContactsList", {
          search_text: search
        })
        .then(() => {
          this.search_group = this.$store.getters["contacts/contactList"];
        });
    },
    addAdditionalMembersToGroup() {
      this.$store
        .dispatch("groups/addMembersToGroup", {
          query: {
            users: this.selected_additionam_group_members,
            emails: this.emails,
            institution_users: this.selected_institutions_users
          },
          id: this.groupId
        })
        .then(() => {
          this.selected_additionam_group_members = [];
          this.emails = [];
          this.selected_institutions_users = [];
          this.additional_members_gorup = !this.additional_members_gorup;
          this.show_invite_modal = false;
          this.refresh();
          Swal.fire({
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          });
        });
    },
    inviteForm() {
      this.$store.dispatch("groups/addNewGroupMembers", {
        name: this.form.name,
      })
      .then(() => {
        this.dialogVisible = false;
        Swal.fire({
          title: "",
          icon: "success",
          text: `${this.localization("Done")}`,
          confirmButtonText: `${this.localization("Ok")}`,
          type: "success",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false,
        });
        this.refresh();
      })
      .catch(() => {
        this.dialogVisible = false;
      });
    },
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--border-card > .el-tabs__header {
  border-radius: 10px !important;
}
.alert-custom {
  background-color: #fffaf0; /* Light background similar to your image */
  border: 1px solid #ffd580; /* Border color to match */
  color: #c35a00; /* Text color to match the orange */
  border-radius: 10px;
}

.alert-custom i {
  color: #c35a00; /* Icon color */
  font-size: 20px;
}

.alert-custom a {
  color: #c35a00;
  text-decoration: none;
}

.alert-custom a:hover {
  text-decoration: underline;
}

::v-deep .el-tabs--border-card, .el-tabs__content{
  border: unset !important;
}

::v-deep .el-tabs__nav-scroll{
  padding: 10px !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-top-color: #DCDFE6 !important;
  border-bottom-color: #DCDFE6 !important;
  border-radius: 7px !important;
}

::v-deep .team_members{
  .el-table .cell{
    overflow: unset !important;
  }
}

::v-deep.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  color: #344054;
}

::v-deep .el-tabs--border-card{
  box-shadow: unset !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header{
  border-bottom: unset !important;
}

.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}

.dialog-center{
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -60%) !important;
  overflow: unset !important;
}

 .swal2-icon .swal2-info .swal2-icon-content{
    display: flex;
    align-items: center;
    font-size: 0.8em !important;
}



//
.el-table__body, .el-table__footer, .el-table__header{
  table-layout: auto !important;
  border-collapse: separate;
}
</style>
